import create from "zustand"
import produce from "immer"

import { GameState } from "@cno/codenames-hp-game"

const store = (set, get) => ({
  G: {} as GameState,
  ctx: {} as any,
  moves: {} as any,
  ui: {} as any,
  connection: {} as any,
  gameID: null as any,
  client: null as any,
  servertimedelta: 0 as number,
  orientationConfig: {
    landscape: {
      tokens: {
        card: { width: 217, height: 140 },
        char: { width: 134, height: 120 },
        duetTimeToken: { width: 75, height: 75 },
      },
      locations: {
        offscreenDeck: { type: "stack", origin: { x: 960, y: -200 } },
        team1Deck: { type: "stack", origin: { x: 190, y: 150 } },
        team2Deck: { type: "stack", origin: { x: 1730, y: 150 } },

        timeDeck: { type: "stack", origin: { x: 1640, y: 335 }, deckRef: null },
        board: {
          type: "grid",
          origin: { x: 525, y: 220 },
          grid: { width: 217, height: 140 },
        },
      },
    },
    portrait: {
      tokens: {
        card: { width: 98, height: 63 },
        char: { width: 59, height: 53 },
        duetTimeToken: { width: 30, height: 30 },
      },
      locations: {
        team1Deck: { type: "stack", origin: { x: 250, y: -50 } },
        team2Deck: { type: "stack", origin: { x: 250, y: -50 } },
        offscreenDeck: { type: "stack", origin: { x: 250, y: -50 } },
        greenDeck: { type: "stack", origin: { x: 250, y: -50 } },
        timeDeck: { type: "stack", origin: { x: 250, y: -50 } },
        board: {
          type: "grid",
          origin: { x: 55, y: 150 },
          grid: { width: 98, height: 63 },
        },
      },
    },
  },
  update: (fn) => set(produce(fn)),
})

export const useGameState = create(store)
