import create from "zustand"
import produce from "immer"
import { persist } from "zustand/middleware"

const defaultState = {
    subscribed: false,
}

const store = (set, get) => ({
    ...defaultState,
    update: (fn) => set(produce(fn)),
})

export const useNewsletterSubscriptionState = create(
    persist(store, {
        name: "newsletterSubscription",
        version: 1,
    })
)
