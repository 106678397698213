/* 
    HOW TO:
    - download Texture Packer (free version is fine)
    - use images from /app/client/assets/lang-120
    - Settings Output - css simple
    - Publish sprite sheet - copy only file classes here, flag and flag-parent should be fine
    - go to tinypng.com and compress flags.png

    Alternative:
    - https://spritegen.website-performance.org/
    - use images from /app/client/assets/lang-120
    - copy new classes here - each flag should have only ".lang-code" class 
      - NO ".bg-lang" or ".flag-lang"
      - language list uses "-"" no "_" - ex. fix ".ar-lb", ".pt-br", etc.

    - keep same names for classes as are before (only .langCode) and
     
*/

import { publicCDN } from "config"

export const Flag = (props) => {
  const { lang, width, className = "", ...rest } = props

  const scale = width / 120

  return (
    <figure className={"flag-parent " + className} {...rest}>
      <div className={`flag ${lang}`}></div>
      <style jsx>{`
        .flag-parent {
          position: relative;
          width: ${width}px;
          height: ${width}px;
        }

        .flag {
          position: absolute;
          left: 50%;
          top: 50%;
          background-image: url(${publicCDN}/img/flags-20230518.png);
          background-repeat: no-repeat;
          transform: scale(${scale}) translate(-${50 / scale}%, -${50 / scale}%);
          border-radius: 50%;
          box-shadow: inset 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
        }

        .af {
          width: 120px;
          height: 120px;
          background-position: -6px -6px;
        }

        .ar-lb {
          width: 120px;
          height: 120px;
          background-position: -138px -6px;
        }

        .ar {
          width: 120px;
          height: 120px;
          background-position: -6px -138px;
        }

        .be {
          width: 120px;
          height: 120px;
          background-position: -138px -138px;
        }

        .bg {
          width: 120px;
          height: 120px;
          background-position: -270px -6px;
        }

        .ca {
          width: 120px;
          height: 120px;
          background-position: -270px -138px;
        }

        .cs {
          width: 120px;
          height: 120px;
          background-position: -6px -270px;
        }

        .da {
          width: 120px;
          height: 120px;
          background-position: -138px -270px;
        }

        .cz {
          width: 120px;
          height: 120px;
          background-position: -270px -270px;
        }

        .de {
          width: 120px;
          height: 120px;
          background-position: -402px -6px;
        }

        .dk {
          width: 120px;
          height: 120px;
          background-position: -402px -138px;
        }

        .en {
          width: 120px;
          height: 120px;
          background-position: -402px -270px;
        }

        .el {
          width: 120px;
          height: 120px;
          background-position: -6px -402px;
        }

        .eo {
          width: 120px;
          height: 120px;
          background-position: -138px -402px;
        }

        .es {
          width: 120px;
          height: 120px;
          background-position: -270px -402px;
        }

        .et {
          width: 120px;
          height: 120px;
          background-position: -402px -402px;
        }

        .fa {
          width: 120px;
          height: 120px;
          background-position: -534px -6px;
        }

        .fi {
          width: 120px;
          height: 120px;
          background-position: -534px -138px;
        }

        .fl {
          width: 120px;
          height: 120px;
          background-position: -534px -270px;
        }

        .fr {
          width: 120px;
          height: 120px;
          background-position: -534px -402px;
        }

        .gr {
          width: 120px;
          height: 120px;
          background-position: -6px -534px;
        }

        .he {
          width: 120px;
          height: 120px;
          background-position: -138px -534px;
        }

        .hr {
          width: 120px;
          height: 120px;
          background-position: -270px -534px;
        }

        .hu {
          width: 120px;
          height: 120px;
          background-position: -402px -534px;
        }

        .id {
          width: 120px;
          height: 120px;
          background-position: -534px -534px;
        }

        .is {
          width: 120px;
          height: 120px;
          background-position: -666px -6px;
        }

        .it {
          width: 120px;
          height: 120px;
          background-position: -666px -138px;
        }

        .ja {
          width: 120px;
          height: 120px;
          background-position: -666px -270px;
        }

        .jp {
          width: 120px;
          height: 120px;
          background-position: -666px -402px;
        }

        .ko {
          width: 120px;
          height: 120px;
          background-position: -666px -534px;
        }

        .lt {
          width: 120px;
          height: 120px;
          background-position: -6px -666px;
        }

        .lv {
          width: 120px;
          height: 120px;
          background-position: -138px -666px;
        }

        .mk {
          width: 120px;
          height: 120px;
          background-position: -270px -666px;
        }

        .mn {
          width: 120px;
          height: 120px;
          background-position: -402px -666px;
        }

        .nl {
          width: 120px;
          height: 120px;
          background-position: -534px -666px;
        }

        .no {
          width: 120px;
          height: 120px;
          background-position: -666px -666px;
        }

        .pl {
          width: 120px;
          height: 120px;
          background-position: -798px -6px;
        }

        .pt-br {
          width: 120px;
          height: 120px;
          background-position: -798px -138px;
        }

        .pt-Br {
          width: 120px;
          height: 120px;
          background-position: -798px -270px;
        }

        .pt {
          width: 120px;
          height: 120px;
          background-position: -798px -402px;
        }

        .ro {
          width: 120px;
          height: 120px;
          background-position: -798px -534px;
        }

        .ru {
          width: 120px;
          height: 120px;
          background-position: -798px -666px;
        }

        .se {
          width: 120px;
          height: 120px;
          background-position: -6px -798px;
        }

        .sk {
          width: 120px;
          height: 120px;
          background-position: -138px -798px;
        }

        .sl {
          width: 120px;
          height: 120px;
          background-position: -270px -798px;
        }

        .sq {
          width: 120px;
          height: 120px;
          background-position: -402px -798px;
        }

        .sr {
          width: 120px;
          height: 120px;
          background-position: -534px -798px;
        }

        .sr_Latn_RS {
          width: 120px;
          height: 120px;
          background-position: -666px -798px;
        }

        .sv {
          width: 120px;
          height: 120px;
          background-position: -798px -798px;
        }

        .th {
          width: 120px;
          height: 120px;
          background-position: -930px -6px;
        }

        .tl {
          width: 120px;
          height: 120px;
          background-position: -930px -138px;
        }

        .tr {
          width: 120px;
          height: 120px;
          background-position: -930px -270px;
        }

        .uk {
          width: 120px;
          height: 120px;
          background-position: -930px -402px;
        }

        .zh {
          width: 120px;
          height: 120px;
          background-position: -930px -534px;
        }
      `}</style>
    </figure>
  )
}
