import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useLocalization } from "features/Localization/useLocalization"
import axios from "axios"
import clsx from "clsx"
import Link from "next/link"
import { plausible } from "plausible"

import { useNewsletterSubscriptionState } from "./useNewsletterSubscriptionState"
import React from "react"
import { ButtonSpinner } from "components/ButtonSpinner"

export const NewsletterSubscriptionForm = (props: { tag: string }) => {
  const { t } = useLocalization()

  const updateNewsletterSubscription = useNewsletterSubscriptionState(
    (state) => state.update
  )
  const subscribed = useNewsletterSubscriptionState((state) => state.subscribed)

  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(
      yup.object({
        email: yup
          .string()
          .required(t("account.login.form.error.required"))
          .email(t("account.login.form.error.email")),
      })
    ),
  })

  const onSubmit = async (values) => {
    let body = {
      email: values.email,
      tag: `cno-${props.tag}`,
      language: navigator.language,
    }

    plausible("Newsletter/Subscribe", {
      props: { where: body.tag, language: navigator.language },
    })

    try {
      let res = await axios.post("/api/newsletter", body)

      updateNewsletterSubscription((s) => {
        s.subscribed = true
      })
    } catch (e) {
      console.log("error", e)
    }
  }

  if (formState.isSubmitSuccessful) {
    return (
      <main
        className={clsx(
          "w-full py-4 text-center",
          props.tag === "homepage"
            ? ""
            : "mt-4 bg-gray-200 border-t border-gray-300 dark:bg-[#161616] dark:border-[#272727]"
        )}
      >
        <h3 className="font-bold mb-2 px-4">{t("newsletter.success.title")}</h3>
        <p className="px-4">{t("newsletter.success.subtitle")}</p>
      </main>
    )
  }

  if (subscribed && props.tag !== "homepage") {
    return null
  }

  return (
    <main
      className={clsx(
        "w-full py-4",
        props.tag === "homepage"
          ? ""
          : "mt-4 bg-gray-200 border-t border-gray-300 dark:bg-[#161616] dark:border-[#272727]"
      )}
    >
      <form className={"flex flex-col items-center px-4"}>
        <div className="flex flex-col items-center ">
          <label htmlFor="email" className="font-bold mb-2 text-center">
            {t("newsletter.form.title")}
          </label>
          <input
            {...register("email")}
            type="email"
            id="email"
            className={clsx(
              `text-center text-base rounded-xl shadow-inset w-3/4 text-black`,
              `dark:bg-[#3f3f3f] dark:text-white dark:shadow-none dark:border dark:border-[#6d6d6d]`
            )}
            placeholder={t("newsletter.form.placeholder")}
            disabled={formState.isSubmitting}
          />
          {formState.errors.email ? (
            <span className="text-red-500">
              {formState.errors.email.message}
            </span>
          ) : null}
        </div>

        <div
          style={{ position: "absolute", left: "-5000px" }}
          aria-hidden="true"
        >
          <input
            type="text"
            name="b_65bdf5c19b5d6e2b9fec19c3b_8d76722f1d"
            tabIndex={-1}
          />
        </div>

        <button
          type="submit"
          disabled={formState.isSubmitting}
          onClick={handleSubmit(onSubmit)}
          className={
            "px-6 py-2 mt-2 rounded-xl border-white border-2 text-white bg-purple hover:bg-darkPurple flex items-center dark:bg-darkPurple dark:hover:bg-blackPurple dark:border-dark-text"
          }
        >
          {formState.isSubmitting ? <ButtonSpinner /> : null}
          {t("newsletter.form.subscribe")}
        </button>

        <p
          className="text-sm mt-4 px-2 text-center"
          dangerouslySetInnerHTML={{
            __html: t("newsletter.form.legal"),
          }}
        />
      </form>
    </main>
  )
}
